import { readContract } from '@wagmi/core';
import axios from 'axios';
import { useState, useEffect, useCallback } from 'react';
import { Address } from 'wagmi';
import { aliceNFT } from '@/config/abi/Alice/aliceNFT';
import { IPFS_GATEWAY, IPFS_URL } from '@/constants/ipfs';
import { NftMetadataStandard } from '@/features/api/types';

const useNFTMetadata = (contractAddress?: Address, tokenId?: bigint, isBridged?: boolean) => {
  const [metadata, setMetadata] = useState<NftMetadataStandard | null>(null);

  const fetchNFTMetadata = useCallback(
    async (contractAddress: Address, tokenId: bigint, isBridged?: boolean) => {
      try {
        const tokenURI = await readContract({
          address: contractAddress,
          abi: aliceNFT,
          functionName: isBridged ? 'burnedTokenURI' : 'tokenURI',
          args: [tokenId],
        });

        const response = await axios.get<NftMetadataStandard>(tokenURI);
        if (response.data.image.startsWith(IPFS_URL)) {
          const ipfsImageUrl = response.data.image.replace(IPFS_URL, IPFS_GATEWAY);
          response.data.image = ipfsImageUrl;
        }
        setMetadata(response.data);
        return response.data;
      } catch (e) {
        console.error(e, '--------------- error?');
        return null;
      }
    },
    []
  );

  useEffect(() => {
    if (contractAddress && tokenId) {
      fetchNFTMetadata(contractAddress, tokenId, isBridged);
    }
  }, [fetchNFTMetadata, contractAddress, tokenId, isBridged]);

  return { metadata, fetchNFTMetadata };
};

export default useNFTMetadata;
