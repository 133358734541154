import { Link as ChakraLink, LinkProps as ChakraLinkProps } from '@chakra-ui/react';
import NextLink from 'next/link';

type LinkProps = ChakraLinkProps & {
  href: string;
};

export const Link = ({ href, children, ...props }: LinkProps) => (
  <NextLink href={href} passHref legacyBehavior>
    <ChakraLink {...props}>{children}</ChakraLink>
  </NextLink>
);
