import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons';
import { FlexProps, ScaleFade, Image, Box, Text, SpinnerProps, styled, keyframes, usePrefersReducedMotion } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
type LoadingProps = FlexProps & {
  isLoading: boolean;
  isError?: boolean;
  size?: SpinnerProps['size'];
};

const hover = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0); }
`

export const Loading = ({
  isLoading,
  isError,
  color = 'green.500',
  size,
  ...props
}: LoadingProps) => {
  const prefersReducedMotion = usePrefersReducedMotion();
  const animation = prefersReducedMotion ? undefined : `${hover} infinite 3s`;
  const { t } = useTranslation(['common', 'pages/dashboard']);
  
  return (
    <Box pt={20} {...props}>
      {isLoading && (
        <CustomScaleFade in={isLoading}>
          <Box m="auto">
            <Box
              position="relative"
              w="240px"
              margin="0 auto 100px"
              display="block"
            >
              <Image
                position="absolute"
                left={{
                  base: "-16px",
                  sm: "0",
                  lg: "0"
                }}
                top="-50px"
                width="91px"
                src="/images/pages/hover-item.png"
                animation={animation}
              />
              <Text
                textAlign="center"
                fontWeight="900"
                fontSize="20px"
                letterSpacing="0.05em"
                pl={20}
                color="brand.staticWhite"
              >
                Loading...
              </Text>
            </Box>
            <Box
              display="block"
              w="100%"
            >
              <Text
                textAlign="center"
                color="brand.staticWhite"
                fontSize="20px"
                fontWeight="600"
              >
                {t('pages/dashboard:components.loading.text')}
              </Text>
            </Box>
          </Box>
        </CustomScaleFade>
      )}
      {!isLoading && (
        <>
          {isError ? (
            <ScaleFade initialScale={0.9} in={!isLoading}>
              <WarningIcon w="3rem" h="3rem" color="red.500" />
            </ScaleFade>
          ) : (
            <ScaleFade initialScale={0.9} in={!isLoading}>
              <CheckCircleIcon w="3rem" h="3rem" color={color} />
            </ScaleFade>
          )}
        </>
      )}
    </Box>
  );
};

const CustomScaleFade = styled(ScaleFade, {
  baseStyle: {
    display: 'flex',
  },
});
