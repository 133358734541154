import { SelectProps, Select as ChakraSelect } from '@chakra-ui/react';
import { FC } from 'react';

type Props = SelectProps & {
  options: {
    name: string;
    value: string;
  }[];
};

export const Select: FC<Props> = ({ options, ...rest }) => {
  return (
    <ChakraSelect {...rest}>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.name}
        </option>
      ))}
    </ChakraSelect>
  );
};
