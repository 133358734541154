import { Button } from '@chakra-ui/react';
import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi';
import { Modal } from '@/components/Elements/';
import { network } from '@/constants/network';

export const NetworkValidationLayout: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation('common');
  const { isConnected } = useAccount();
  const { chain } = useNetwork();
  const [error, setError] = useState<{ title: string; description: string }>({
    title: '',
    description: '',
  });
  const [isOpen, setIsOpen] = useState(false);
  const { switchNetwork, isLoading } = useSwitchNetwork({
    chainId: network.chainId,
  });

  useEffect(() => {
    if (!isConnected) {
      return;
    }

    if (chain?.id !== network.chainId) {
      setError({
        title: t('common.components.layouts.networkValidation.wrongNetwork.title'),
        description: t('common.components.layouts.networkValidation.wrongNetwork.description'),
      });
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [isConnected, chain, t]);

  const NetworkIcon = network.icon;

  return (
    <>
      {children}
      <Modal title={error.title} description={error.description} isOpen={isOpen}>
        <Button
          mt={4}
          w="100%"
          leftIcon={<NetworkIcon boxSize={5} />}
          onClick={() => switchNetwork?.()}
          isLoading={isLoading}
        >
          {network.name}
        </Button>
      </Modal>
    </>
  );
};
