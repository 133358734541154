import { Th as ChakraTh, TableColumnHeaderProps } from '@chakra-ui/react';

type ThProps = TableColumnHeaderProps;

export const Th = (props: ThProps) => (
  <ChakraTh
    textTransform="none"
    fontSize="sm"
    color="single.black"
    borderBottomColor="single.deepGray"
    py={5}
    {...props}
  />
);
