import { GOOGLE_ANALYTICS_ID } from '@/config';

export const isExistsGaId = GOOGLE_ANALYTICS_ID && GOOGLE_ANALYTICS_ID !== '';

export const pageView = (page_path: string) => {
  window.gtag('config', GOOGLE_ANALYTICS_ID, {
    page_path,
  });
};

export const event = ({
  action,
  category,
  label,
  value = '',
}: {
  action: string;
  category: string;
  label: string;
  value: unknown;
}) => {
  if (!isExistsGaId) {
    return;
  }

  window.gtag('event', action, {
    event_category: category,
    event_label: JSON.stringify(label),
    value,
  });
};
