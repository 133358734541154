import Script from 'next/script';

import { GOOGLE_ANALYTICS_ID } from '@/config';
import { isExistsGaId } from '@/lib/gtag';

export const GoogleAnalytics = () => (
  <>
    {/* Global site tag (gtag.js) - Google Analytics */}
    {isExistsGaId && (
      <>
        <Script
          defer
          src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_ID}`}
          strategy="afterInteractive"
        />
        <Script
          id="ga"
          defer
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${GOOGLE_ANALYTICS_ID}', {
                      page_path: window.location.pathname,
                    });
                  `,
          }}
        />
      </>
    )}
  </>
);
