import { Icon, IconButton } from '@chakra-ui/react';
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';

type ArrowButtonProps = {
  type: 'prev' | 'next';
  onClick: () => void;
};

const icon = {
  prev: IoChevronBack,
  next: IoChevronForward,
};

export const ArrowButton = ({ type, onClick }: ArrowButtonProps) => (
  <IconButton
    icon={<Icon as={icon[type]} />}
    aria-label={`slick ${type} button`}
    w="40px"
    h="40px"
    rounded="full"
    pos="absolute"
    left={type === 'prev' ? { base: '30%', md: '5px' } : 'unset'}
    right={type === 'next' ? { base: '30%', md: '5px' } : 'unset'}
    top={{ base: '90%', md: '50%' }}
    transform="translate(0%, -50%)"
    bg="single.deepWhite"
    zIndex={2}
    border="1px solid white"
    onClick={onClick}
  />
);
