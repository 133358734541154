import { useState } from 'react';
import Slider from 'react-slick';

import { ArrowButton } from './ArrowButton';

export type SlickProps = Slider['props'];

export const Slick = ({
  children,
  speed = 500,
  slidesToShow = 4,
  slidesToScroll = 1,
  dots = true,
  infinite = true,
  arrows = true,
  nextArrow,
  prevArrow,
  ...settings
}: SlickProps) => {
  const [slider, setSlider] = useState<Slider | null>(null);

  return (
    <Slider
      speed={speed}
      slidesToShow={slidesToShow}
      slidesToScroll={slidesToScroll}
      dots={dots}
      infinite={infinite}
      arrows={arrows}
      nextArrow={nextArrow || <ArrowButton type="next" onClick={() => slider?.slickNext()} />}
      prevArrow={prevArrow || <ArrowButton type="prev" onClick={() => slider?.slickPrev()} />}
      responsive={[
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ]}
      ref={(slider) => setSlider(slider)}
      {...settings}
    >
      {children}
    </Slider>
  );
};
