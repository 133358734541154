import {
  Link,
  Heading,
  Container,
  Box,
  HStack,
  Text,
  Flex,
  Image,
  useToast,
  useDisclosure,
  ScaleFade,
  usePrefersReducedMotion,
  keyframes,
  styled,
  Button,
  Modal,
  ModalContent,
} from '@chakra-ui/react';
import { waitForTransaction } from '@wagmi/core';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { useState, useEffect } from 'react';
import { useReward } from 'react-rewards';
import { useAccount, usePrepareContractWrite, useContractWrite } from 'wagmi';
import { Toast } from '@/components/Elements';
import { Head } from '@/components/Elements/Head';
import { Loading } from '@/components/Elements/Loading';
import { EthToMantle } from '@/components/Icons/chains/EthereumToMantle';
import { ArrowIcon } from '@/components/Icons/originals/Arrow';
import { CheckIcon } from '@/components/Icons/originals/Check';
import { AliceLogo } from '@/components/Icons/slash/AliceLogo';
import { Wrapper } from '@/components/Layouts';
import { aliceNFT } from '@/config/abi/Alice/aliceNFT';
import ContractAddress from '@/constants/contractAddress';
import useFetchMyBridgedAliceNFT, {
  BridgedNFTList,
} from '@/features/common/hooks/useFetchMyBridgedAliceNFT';
import useFetchMyEthAliceNFT, {
  AliceNFTList,
  ReturnValue,
} from '@/features/common/hooks/useFetchMyEthAliceNFT';
import useNFTMetadata from '@/features/common/hooks/useFetchNFTMetadata';

const SelectedBar = ({ nftCounts, selected }: { nftCounts: number; selected: number }) => {
  return (
    <Box textAlign="center" mb={6}>
      <Text
        display="inline-block"
        borderRadius="32px"
        bgGradient="linear(to-r, #185B4E, #1C7A57)"
        px={8}
        py={0.5}
        color="brand.staticWhite"
        minW="212px"
      >
        <Text as="span" fontFamily="Noto Sans JP, sans-serif" fontWeight="bold" fontSize="16px">
          {selected} selected out of {nftCounts}
        </Text>
      </Text>
    </Box>
  );
};

const Bridging = ({ isLoading }: { isLoading: boolean }) => {
  const spin = keyframes`
    0% { transform: rotate(0); }
    100% { transform: rotate(365deg); }
  `;
  const prefersReducedMotion = usePrefersReducedMotion();
  const animation = prefersReducedMotion ? undefined : `${spin} 11s infinite linear`;

  const CustomScaleFade = styled(ScaleFade, {
    baseStyle: {
      display: 'flex',
    },
  });
  return (
    <Box
      w="100%"
      justifyContent="center"
      alignItems="center"
      position="sticky"
      top="50%"
      left="50%"
      zIndex="999"
      display={isLoading ? 'block' : 'none'}
    >
      <CustomScaleFade in={isLoading} justifyContent="center">
        <Flex>
          <Text as="span">
            <Image src="/images/pages/loading.png" animation={animation} />
          </Text>
          <Text color="brand.staticWhite" fontWeight="900" fontSize="28px" ml="12px">
            Processing
          </Text>
        </Flex>
      </CustomScaleFade>
    </Box>
  );
};

const BridgeAliceNFT = ({
  write,
  isLoading,
  selectedAliceNFTTokenIds,
}: {
  write: (() => void) | undefined;
  isLoading: boolean;
  selectedAliceNFTTokenIds: bigint[];
}) => {
  const { address } = useAccount();
  return (
    <Button
      maxWidth={{ base: '180px', md: '212px' }}
      w="100%"
      h="auto"
      mt={6}
      background="none"
      onClick={() => write?.()}
      isDisabled={
        selectedAliceNFTTokenIds?.length === 0 || !address || isLoading || write === undefined
      }
      border="2px solid #fff"
      borderRadius={36}
      p="2px 0"
      margin="auto"
      display="block"
    >
      <Text
        as="span"
        borderRadius={36}
        fontWeight="bold"
        border="1px solid #fff"
        maxWidth="99%"
        width="204px"
        p="12px 0"
        margin="auto"
        lineHeight="1.2"
        display="block"
        fontSize={{ base: '26px', md: `${isLoading ? '28px' : '35px'}` }}
      >
        {isLoading ? 'Please wait' : 'Bridge'}
      </Text>
    </Button>
  );
};

const MyAliceNFTList = ({
  nftInfo,
  isLoading,
  onClick,
  isSelected,
}: {
  nftInfo: ReturnValue['nftList'][number];
  isLoading: boolean;
  onClick: () => void;
  isSelected: boolean;
}) => {
  const { metadata } = useNFTMetadata(nftInfo.contractAddress, nftInfo.tokenId);

  return (
    <>
      <AliceCardItem
        imageURL={metadata?.image ?? ''}
        title={metadata?.name ?? ''}
        isLoading={isLoading}
        onClick={onClick}
        isBridged={false}
        isSelected={isSelected}
      />
    </>
  );
};

const MyBridgedAliceNFTList = ({ nftInfo }: { nftInfo: ReturnValue['nftList'][number] }) => {
  const { metadata } = useNFTMetadata(nftInfo.contractAddress, nftInfo.tokenId, true);

  return (
    <>
      <AliceCardItem
        imageURL={metadata?.image ?? ''}
        title={metadata?.name ?? ''}
        onClick={undefined}
        isLoading={false}
        isBridged
        isSelected={false}
      />
    </>
  );
};

const AliceCardItem = ({
  imageURL,
  title,
  onClick,
  isLoading,
  isBridged,
  isSelected,
}: {
  imageURL: string;
  title: string;
  isLoading: boolean;
  isBridged: boolean;
  onClick?: () => void;
  isSelected: boolean;
}) => {
  const handleClick = () => {
    onClick?.();
  };

  return (
    <Flex
      flexShrink={0}
      role="group"
      onClick={handleClick}
      justifyContent="center"
      cursor={isBridged ? 'unset' : 'pointer'}
      w={{
        base: '50%',
        md: '33.333%',
        lg: '25%',
      }}
      mb={6}
    >
      <Flex
        position="relative"
        flexDir="column"
        backgroundColor="brand.gray.0"
        borderRadius={16}
        bg="none"
      >
        {isSelected && !isBridged && (
          <Box position="absolute" bottom="48px" right="4px">
            <CheckIcon />
          </Box>
        )}
        <Image
          boxSize={{ base: '8rem', md: '9.5rem' }}
          objectFit="cover"
          src={imageURL}
          borderRadius={16}
          filter={isLoading ? 'blur(2px)' : 'none'}
        />
        <Flex flexDir="column" p={3} maxWidth={60}>
          <Flex flexDir="row" justifyContent="center" alignItems="center">
            <Text fontSize="sm" fontWeight={700} color="brand.staticWhite">
              {title}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

const SuccessModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
}) => {
  const { t } = useTranslation(['common', 'pages/dashboard']); // Ensure this line is present
  return (
    <Modal
      size={{ base: 'sm', md: '4xl' }}
      isOpen={isOpen}
      onClose={() => {}}
      isCentered
      motionPreset="slideInBottom"
    >
      <ModalContent borderRadius={28}>
        <Flex
          p={{ base: 4, md: 7 }}
          pb={{ base: 8, md: 0 }}
          pl={{ base: 4, md: 0 }}
          alignItems="center"
        >
          <Box display={{ base: 'none', md: 'block' }}>
            <Image objectFit="cover" src="/images/pages/mantle-alice.png" filter="none" />
          </Box>
          <Box w={{ base: '98%', md: '50%' }} margin="auto" textAlign="center">
            <Text
              as="h4"
              mb={8}
              fontSize={{ base: '36px', md: '40px' }}
              fontWeight="bold"
              textAlign="center"
              letterSpacing="0.05em"
              background="linear-gradient(90.25deg,#c53fe0 28.43%,#ea3e94 63.32%,#ea3e94 98.19%)"
              backgroundClip="text"
              backgroundSize="100% 10%"
            >
              Congratulations!
            </Text>
            <Text
              mb={8}
              textAlign="center"
              fontFamily="Noto Sans JP, sans-serif"
              fontWeight="900"
              fontSize={{ base: '18px', md: '18px' }}
              color="#6A2E56"
            >
              {t('pages/dashboard:components.execute-bridge.on-success.modal-success.message')}
            </Text>
            <Text
              textAlign="center"
              fontFamily="Noto Sans JP, sans-serif"
              fontWeight="900"
              fontSize={{ base: '18px', md: '18px' }}
              color="#6A2E56"
            >
              {t('pages/dashboard:components.execute-bridge.on-success.modal-success.description')}
            </Text>
            <Box mt="40px">
              <Button
                display="inline-block"
                height="auto"
                outline="none"
                border="none"
                background="linear-gradient(170.25deg,#c53fe0 28.43%,#ea3e94 63.32%,#ea3e94 98.19%)"
                py={4}
                px={8}
                color="brand.staticWhite"
                borderRadius={28}
                fontSize="18px"
                letterSpacing="0.05em"
                fontWeight="300"
                mb={4}
                minW="265px"
                _hover={{
                  opacity: 0.8,
                }}
                onClick={onClose}
              >
                Continue to bridge
                <Text as="span" ml="6px">
                  <ArrowIcon />
                </Text>
              </Button>
              <Link
                href="https://alice.slash.vision/"
                display="inline-block"
                background="linear-gradient(170.25deg,#c53fe0 28.43%,#ea3e94 63.32%,#ea3e94 98.19%)"
                py={4}
                p="16px 0px 16px 32px"
                color="brand.staticWhite"
                borderRadius={28}
                fontSize="18px"
                letterSpacing="0.05em"
                fontWeight="300"
                minW="265px"
                _hover={{
                  opacity: 0.8,
                }}
              >
                Go to TOP
                <Text as="span" ml="40px">
                  <ArrowIcon />
                </Text>
              </Link>
            </Box>
          </Box>
        </Flex>
      </ModalContent>
    </Modal>
  );
};

const HaveAliceNfts = ({
  nftList,
  onOpen,
  isAnimating,
  reward,
}: {
  nftList: AliceNFTList;
  onOpen: () => void;
  isAnimating: boolean;
  reward: () => void;
}) => {
  const [selectedAliceNFTTokenIds, setSelectedAliceNFTTokenIds] = useState<bigint[]>([]);
  const [aliceNftList, setAliceNftList] = useState<AliceNFTList>(nftList);
  const { t } = useTranslation(['common', 'pages/dashboard']);
  const toast = useToast();
  const router = useRouter();

  const selectAllAliceNFT = (nftList: AliceNFTList) => {
    const allTokenIds = nftList.map((item) => item.tokenId);
    setSelectedAliceNFTTokenIds(allTokenIds);
  };

  useEffect(() => {
    selectAllAliceNFT(aliceNftList);
  }, [aliceNftList]);

  const { config } = usePrepareContractWrite({
    address: ContractAddress.aliceNFT,
    abi: aliceNFT,
    functionName: 'batchBridge',
    args: selectedAliceNFTTokenIds ? [selectedAliceNFTTokenIds] : undefined,
  });

  const { address } = useAccount();
  const formattedAddress = address
    ? `${address.substring(0, 6)}...${address.substring(address.length - 4)}`
    : '';

  const { write, isLoading } = useContractWrite({
    ...config,
    onSuccess: async (writeResult) => {
      const transactionReceipt = await waitForTransaction({
        hash: writeResult.hash,
      });
      if (transactionReceipt.status === 'success') {
        onSuccessBridge();
        toast({
          title: t('pages/dashboard:components.execute-bridge.on-success.title'),
          description: t('pages/dashboard:components.execute-bridge.on-success.description'),
          status: 'success',
          variant: 'subtle',
          isClosable: true,
        });
        selectedAliceNFTTokenIds.forEach((tokenId) => {
          setAliceNftList((currentList) => {
            return currentList.filter((nftItem) => nftItem.tokenId !== tokenId);
          });
        });
        return;
      } else {
        console.log(transactionReceipt);
        router.reload();
        return;
      }
    },
    onError: (error) => {
      console.log(error, '---------------------- error wen bridging');
      toast({
        title: t('pages/dashboard:components.execute-bridge.on-error.title'),
        description: t('pages/dashboard:components.execute-bridge.on-error.description'),
        status: 'error',
        variant: 'subtle',
        isClosable: true,
      });
      return;
    },
  });

  const onClickAliceNFTToBridge = (tokenId: bigint) => {
    setSelectedAliceNFTTokenIds((currentSelected) => {
      const index = currentSelected.indexOf(tokenId);
      if (index > -1) {
        return currentSelected.filter((id) => id !== tokenId);
      } else {
        return [...currentSelected, tokenId];
      }
    });
  };

  const onSuccessBridge = () => {
    console.log('onSuccessBridge'); // TODO: Implement
    if (!isAnimating) {
      reward();
    }
    onOpen();
  };

  return (
    <Box w="100%" margin="auto">
      <Box mb={10}>
        <Heading
          as="h2"
          mb={2}
          letterSpacing="0.05em"
          lineHeight="1.13"
          fontWeight="bold"
          textAlign="center"
          fontSize="17px"
          color="brand.staticWhite"
          display="block"
        >
          Your Address
        </Heading>
        <Text
          letterSpacing="0.05em"
          lineHeight="1.13"
          fontWeight="bold"
          textAlign="center"
          fontSize="17px"
          color="brand.staticWhite"
        >
          {formattedAddress}
        </Text>
      </Box>
      <Box>
        <Text color="brand.staticWhite" textAlign="center" fontWeight="600" mb={5}>
          Your Alice NFT
        </Text>
        <SelectedBar nftCounts={aliceNftList.length} selected={selectedAliceNFTTokenIds.length} />
      </Box>
      <Flex p={{ base: 0, md: 2 }} flexDir="column" alignItems="center">
        <Box w="100%">
          <Text
            color="brand.staticWhite"
            fontSize={{ base: '13px', md: 'medium' }}
            fontWeight={700}
            mb={6}
            textAlign="center"
          >
            {t('pages/dashboard:pages.dashboard.description1')}
          </Text>
          <HStack
            maxHeight="410px"
            overflow="scroll"
            justifyContent="flex-start"
            flexWrap="wrap"
            gap="0"
            mb={8}
            position="relative"
          >
            <Bridging isLoading={isLoading} />
            {aliceNftList.map((nftItem) => (
              <MyAliceNFTList
                key={`${nftItem.tokenId}`}
                nftInfo={nftItem}
                isLoading={isLoading}
                onClick={() => onClickAliceNFTToBridge(nftItem.tokenId)}
                isSelected={selectedAliceNFTTokenIds.includes(nftItem.tokenId)}
              />
            ))}
          </HStack>
        </Box>
        <BridgeAliceNFT
          selectedAliceNFTTokenIds={selectedAliceNFTTokenIds}
          write={write}
          isLoading={isLoading}
        />
      </Flex>
    </Box>
  );
};

const HaveBridgedAliceNfts = ({
  serverErrorMessage,
  isFetching,
}: {
  serverErrorMessage: string;
  isFetching: boolean;
}) => {
  const [bridgedAliceNftList, setBridgedAliceNftList] = useState<BridgedNFTList>([]);
  const [isLoadingBridgedAlice, setIsLoadingBridgedAlice] = useState(true);
  const { bridgedNftList, errorMessage } = useFetchMyBridgedAliceNFT();
  const { t } = useTranslation(['common', 'pages/dashboard']);
  const toast = useToast();
  const router = useRouter();
  useEffect(() => {
    setBridgedAliceNftList((currentList) => {
      const newList = [...currentList];
      bridgedNftList.forEach((nftItem) => {
        if (!currentList.some((item) => item.tokenId === nftItem.tokenId)) {
          newList.push(nftItem);
        }
      });
      return newList;
    });
  }, [bridgedNftList]);

  useEffect(() => {
    if (!!errorMessage && errorMessage !== serverErrorMessage) {
      toast({
        isClosable: true,
        position: 'top',
        render: () => <Toast status="error" title={errorMessage} />,
      });
      router.push('/signin');
      return;
    }
    if (isLoadingBridgedAlice !== isFetching) {
      setIsLoadingBridgedAlice(isFetching);
    }
  }, [
    errorMessage,
    toast,
    serverErrorMessage,
    router,
    isFetching,
    bridgedNftList,
    isLoadingBridgedAlice,
  ]);

  return (
    <>
      {!isLoadingBridgedAlice && bridgedAliceNftList?.length > 0 && (
        <Flex mt={10} p={{ base: 0, md: 2 }} flexDir="column" alignItems="center">
          <Text
            color="brand.staticWhite"
            fontSize={{ base: '13px', md: 'medium' }}
            fontWeight={700}
            mb={6}
          >
            {t('pages/dashboard:pages.dashboard.description3')}
          </Text>
          <HStack
            maxHeight="400px"
            overflow="scroll"
            justifyContent="flex-start"
            flexWrap="wrap"
            gap="0"
            position="relative"
            w="100%"
          >
            {bridgedAliceNftList.map((nftItem) => (
              <MyBridgedAliceNFTList key={`${nftItem.tokenId}`} nftInfo={nftItem} />
            ))}
          </HStack>
        </Flex>
      )}
    </>
  );
};

const DontHaveAliceNft = () => {
  const { t } = useTranslation(['common', 'pages/dashboard']);
  return (
    <Box pb={6}>
      <Flex flexDir="column" justifyContent="center" alignItems="center" mb={8}>
        <Text
          mb="40px"
          color="brand.staticWhite"
          fontSize={{ base: '15px', md: 'medium' }}
          fontWeight={700}
        >
          {t('pages/dashboard:components.buy-alice.ethereum.description')}
        </Text>
        <Flex position="relative" flexShrink={0} role="group" cursor="pointer">
          <Link
            href="https://opensea.io/collection/slash-alice-nft"
            isExternal
            display="inline-block"
            background="linear-gradient(170.25deg,#c53fe0 28.43%,#ea3e94 63.32%,#ea3e94 98.19%)"
            py={4}
            p="16px 0px 16px 48px"
            color="brand.staticWhite"
            borderRadius={28}
            fontSize="18px"
            letterSpacing="0.05em"
            fontWeight="300"
            minW="265px"
            _hover={{
              opacity: 0.8,
            }}
          >
            Go to Opensea
            <Text as="span" ml="16px">
              <ArrowIcon />
            </Text>
          </Link>
        </Flex>
      </Flex>
      <Flex flexDir="column" justifyContent="center" alignItems="center">
        <Text
          mb="40px"
          color="brand.staticWhite"
          fontSize={{ base: '15px', md: 'medium' }}
          fontWeight={700}
        >
          {t('pages/dashboard:components.buy-alice.mantle.description')}
        </Text>
        <Flex position="relative" flexShrink={0} role="group" cursor="pointer">
          <Link
            href="https://element.market/collections/slash-alice-nft"
            isExternal
            display="inline-block"
            background="linear-gradient(170.25deg,#c53fe0 28.43%,#ea3e94 63.32%,#ea3e94 98.19%)"
            py={4}
            p="16px 0px 16px 48px"
            color="brand.staticWhite"
            borderRadius={28}
            fontSize="18px"
            letterSpacing="0.05em"
            fontWeight="300"
            minW="265px"
            _hover={{
              opacity: 0.8,
            }}
          >
            Go to Element
            <Text as="span" ml="16px">
              <ArrowIcon />
            </Text>
          </Link>
        </Flex>
      </Flex>
    </Box>
  );
};

const Home = () => {
  const { t } = useTranslation(['common', 'pages/dashboard']);
  const [isLoadingEthAlice, setIsLoadingEthAlice] = useState(true);
  const [serverErrorMessage, setServerErrorMessage] = useState<string>('');
  const { reward, isAnimating } = useReward('rewardId', 'confetti', {
    lifetime: 350,
    spread: 90,
    elementCount: 180,
    elementSize: 18,
  });
  const { address } = useAccount();
  const router = useRouter();
  const toast = useToast();

  const { isOpen, onOpen, onClose } = useDisclosure();
  useEffect(() => {
    if (!address) {
      router.push('/signin');
    }
  }, [address, router]);

  const { nftList, errorMessage, isFetching } = useFetchMyEthAliceNFT();

  useEffect(() => {
    if (!!errorMessage && errorMessage !== serverErrorMessage) {
      setServerErrorMessage(errorMessage);
      toast({
        isClosable: true,
        position: 'top',
        render: () => <Toast status="error" title={errorMessage} />,
      });
      router.push('/signin');
      return;
    }
    if (isLoadingEthAlice !== isFetching) {
      setIsLoadingEthAlice(isFetching);
    }
  }, [errorMessage, serverErrorMessage, isFetching, isLoadingEthAlice, t, toast, router]);

  return (
    <>
      <Head />
      <Wrapper
        py={{
          base: 16,
          mb: 28,
        }}
        width="100%"
        maxW={{
          base: '90%',
        }}
        margin={{
          base: 'auto',
        }}
      >
        <Box margin="0 auto" mb={{ base: 4, md: 16 }} w={{ base: '195px', md: '300px' }}>
          <AliceLogo />
        </Box>
        <Text
          as="h2"
          textAlign="center"
          color="brand.staticWhite"
          fontSize={{
            base: '24px',
            md: '40px',
          }}
          fontWeight="bold"
          letterSpacing="0.05em"
          mb={{ base: 6, md: 10 }}
        >
          Mantle Bridge Station
        </Text>
        <Text
          color="brand.staticWhite"
          fontSize={{ base: '12px', lg: '17px' }}
          fontWeight="600"
          maxW="530px"
          margin={{ base: '0 auto 24px', md: '0 auto 40px' }}
          letterSpacing={{ base: '0', lg: '0.05em' }}
          textAlign="center"
          whiteSpace="pre-wrap"
        >
          {t('common:common.components.layouts.fixedContent.description')}
        </Text>
        <Text
          color="brand.staticWhite"
          fontSize={{ base: '13px', md: '17px' }}
          fontWeight="600"
          margin={{ base: '0 auto', md: '0 auto 40px' }}
          letterSpacing="0.05em"
        >
          {t('common:common.components.layouts.fixedContent.contact')}
          <Link textDecoration="underline" href="https://discord.com/invite/gk25cyBs78" isExternal>
            {t('common:common.components.layouts.fixedContent.contactLink')}
          </Link>
        </Text>
        <Box
          margin={{ base: '0 auto 32px', md: '0 auto 56px' }}
          w={{
            base: '90%',
            lg: '658px',
          }}
        >
          <EthToMantle />
        </Box>
        <Container
          width="100%"
          height="100%"
          maxWidth="750px"
          bg="brand.boxWhite"
          px={{ base: 4, md: 9 }}
          pt={12}
          pb={10}
          borderRadius="20px"
          border="1px solid white"
          position="relative"
        >
          {isLoadingEthAlice ? (
            <Loading isLoading={isLoadingEthAlice} />
          ) : nftList.length === 0 ? (
            <DontHaveAliceNft />
          ) : (
            <HaveAliceNfts
              nftList={nftList}
              onOpen={onOpen}
              reward={reward}
              isAnimating={isAnimating}
            />
          )}
          <HaveBridgedAliceNfts serverErrorMessage={serverErrorMessage} isFetching={isFetching} />
        </Container>

        <SuccessModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} />

        <Box
          minW="1200px"
          h="100vh"
          position="fixed"
          zIndex="-1"
          top="0"
          left="50%"
          id="rewardId"
        />
      </Wrapper>
    </>
  );
};

export default Home;
