import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons';
import { HStack, Link, Text, VStack } from '@chakra-ui/react';
import { FC } from 'react';

type Props = {
  status: 'success' | 'error';
  title: string;
  href?: string;
  description?: string;
};

export const Toast: FC<Props> = ({ status, href, description, title }) => {
  return (
    <HStack
      bg={status === 'success' ? 'green.600' : 'red.600'}
      borderRadius={8}
      p={4}
      alignItems="center"
      spacing={4}
    >
      {status === 'success' && <CheckCircleIcon color="brand.staticWhite" />}
      {status === 'error' && <WarningIcon color="brand.staticWhite" />}

      <VStack flex={1} alignItems="flex-start">
        <Text fontWeight="bold" color="brand.staticWhite">
          {title}
        </Text>
        {href && (
          <Link
            isExternal
            href={href}
            color="brand.staticWhite"
            fontWeight="semibold"
            fontSize="lg"
            display="flex"
            flexDir="row"
            alignItems="center"
          >
            <Text mr={1}>{description}</Text>
          </Link>
        )}
      </VStack>
    </HStack>
  );
};
