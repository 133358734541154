// import { readContract } from '@wagmi/core';
import { useTranslation } from 'next-i18next';
import { useState, useCallback, useEffect } from 'react';
import { Address } from 'viem';
import { useAccount, useContractRead } from 'wagmi';
import { aliceNFT } from '@/config/abi/Alice/aliceNFT';
import ContractAddress from '@/constants/contractAddress';

export type BridgedNFTList = {
  tokenId: bigint;
  contractAddress: Address;
}[];

export type ReturnValue = {
  bridgedNftList: BridgedNFTList;
  errorMessage: string | undefined;
  isFetching: boolean;
};

const useFetchMyBridgedAliceNFT = (): ReturnValue => {
  const { t } = useTranslation(['pages/dashboard']);
  const { address } = useAccount();

  const [bridgeAliceNFTList, setBridgedAliceNFTList] = useState<BridgedNFTList>([]);

  const { data: bridgedOfOwnerData, isLoading } = useContractRead({
    address: ContractAddress.aliceNFT,
    abi: aliceNFT,
    functionName: 'bridgedOfOwner',
    args: address ? [address] : undefined,
  });

  const setNewNFTList = useCallback(
    async (tokenIds: readonly bigint[], contractAddress: Address) => {
      try {
        const newBridgedTokenIdList: BridgedNFTList = tokenIds.map((tokenId) => ({
          tokenId,
          contractAddress,
        }));

        setBridgedAliceNFTList(newBridgedTokenIdList);
      } catch (e) {
        console.error(e);
        return {
          bridgedTokenIdList: [],
          errorMessage: t('pages/dashboard:components.bridged-alice.fetch.failed.description'),
          isFetching: false,
        };
      }
    },
    [t]
  );
  useEffect(() => {
    if (bridgedOfOwnerData) {
      // Assuming bridgedOfOwnerData is the array of tokenIds you want to use
      // Adjust the logic here if bridgedOfOwnerData needs to be transformed
      setNewNFTList(bridgedOfOwnerData, ContractAddress.aliceNFT);
    }
  }, [bridgedOfOwnerData, setNewNFTList]);

  return {
    bridgedNftList: bridgeAliceNFTList,
    errorMessage: undefined,
    isFetching: isLoading,
  };
};

export default useFetchMyBridgedAliceNFT;
