import { Link as ChakraLink, LinkProps as ChakraLinkProps } from '@chakra-ui/react';
import { Link as Scroll, LinkProps as ScrollProps } from 'react-scroll';

type ScrollLinkProps = ChakraLinkProps & ScrollProps;

export const ScrollLink = ({
  to,
  duration,
  offset,
  children,
  color = 'single.paleBlack',
  fontWeight = 500,
  fontSize = 'md',
  textDecor = 'none',
  _hover,
  onClick,
}: ScrollLinkProps) => (
  <ChakraLink
    as={Scroll}
    to={to}
    color={color}
    fontSize={fontSize}
    fontWeight={fontWeight}
    duration={duration || 600}
    offset={offset || -30}
    onClick={onClick}
    _hover={{ textDecor, ..._hover }}
    smooth
  >
    {children}
  </ChakraLink>
);
