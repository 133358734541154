import Head from 'next/head';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useTranslation } from 'react-i18next';
import { NetworkValidationLayout } from '@/components/Layouts/NetworkValidation';
import { getDashboardLayout } from '@/components/Layouts/getDashboardLayout';
import { NextPageWithLayout } from '@/pages/_app';
import Home from '@/routes/Home';
export const getStaticProps = async ({ locale }: { locale: string }) => ({
  props: {
    ...(await serverSideTranslations(locale, ['common', 'pages/dashboard'])),
  },
});

const Index: NextPageWithLayout = () => {
  const { t } = useTranslation(['common']);

  return (
    <NetworkValidationLayout>
      <Head>
        <title>{t('common.site.title')}</title>
      </Head>
      <Home />
    </NetworkValidationLayout>
  );
};

Index.getLayout = getDashboardLayout();

export default Index;
