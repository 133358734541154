import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import useSWR from 'swr';
import { Address } from 'viem';
import { useAccount } from 'wagmi';
import { GetAliceNFTListByEOARequest } from '@/features/api/types';
import { getFetcher } from '@/lib/axios';

export type AliceNFTList = {
  tokenId: bigint;
  contractAddress: Address;
}[];

export type ReturnValue = {
  nftList: AliceNFTList;
  errorMessage: string | undefined;
  isFetching: boolean;
};

const useFetchMyEthAliceNFT = (): ReturnValue => {
  const { t } = useTranslation(['common', 'pages/dashboard']);
  const { address } = useAccount();
  const [isFetching, setIsFetching] = useState(true);

  const fetcher = async (url: string) => {
    try {
      const response = await getFetcher<GetAliceNFTListByEOARequest>(url, {
        eoaAddress: address,
      });
      setIsFetching(false);
      return { response, error: null };
    } catch (error) {
      const errorMessage = t('pages/dashboard:components.errors.fetch-failed.title');
      console.error(errorMessage);
      setIsFetching(false);
      return { response: [], error: errorMessage };
    }
  };

  const { data } = useSWR(
    address ? `get-my-alice-nfts` : null, // Pass null to disable fetching when address is undefined
    fetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  if (data?.error) {
    return { nftList: [], errorMessage: data?.error, isFetching: isFetching };
  }

  const nftList =
    data?.response?.map((nft: { tokenId: string; tokenAddress: string }) => ({
      tokenId: BigInt(nft.tokenId),
      contractAddress: nft.tokenAddress,
    })) || [];

  return {
    nftList,
    errorMessage: undefined,
    isFetching: isFetching,
  };
};

export default useFetchMyEthAliceNFT;
