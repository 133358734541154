import { VStack, Text } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
import { BasicModalLayout } from '@/components/Modal/Parts';

type ModalProps = {
  isOpen: boolean;
  onClose?: () => void;
  title: string;
  description: string;
} & PropsWithChildren;

export const Modal = ({ isOpen, onClose, title, description, children }: ModalProps) => {
  return (
    <BasicModalLayout title={title} isOpen={isOpen} onClose={() => onClose?.()}>
      <VStack>
        <Text whiteSpace="pre-wrap">{description}</Text>
        {children}
      </VStack>
    </BasicModalLayout>
  );
};
