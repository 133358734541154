import { getAddress } from 'viem';
import { isPremain, isUseMainnet } from './network';

const ContractAddress = isUseMainnet
  ? !isPremain
    ? {
        // Production Mainnet
        aliceNFT: getAddress('0x1d762CA34138c6b1e85d52b6edD734b831a0ddeB'),
      }
    : {
        // Premain Mainnet
        aliceNFT: getAddress('0x1d762CA34138c6b1e85d52b6edD734b831a0ddeB'),
      }
  : {
      // Testnet
      aliceNFT: getAddress('0x70Bf4fd5d097bBA1Ed1b80a18948eDE02b30b646'),
    };
export default ContractAddress;
