import { Link as ChakraLink, LinkProps as ChakraLinkProps } from '@chakra-ui/react';

import { LinkIcon } from '@/components/Icons/originals';

type ExternalLinkProps = ChakraLinkProps & {
  isDisplayIcon?: boolean;
};

export const ExternalLink = ({ children, isDisplayIcon, ...props }: ExternalLinkProps) => (
  <ChakraLink display="flex" alignItems="center" {...props} isExternal>
    {children}
    {isDisplayIcon && <LinkIcon ml={1} fill="brand.gray.600" boxSize={2.5} />}
  </ChakraLink>
);
